
import { defineComponent, onMounted, ref } from 'vue'
import html2canvas from 'html2canvas'
import wx from 'weixin-js-sdk'
import { useRoute } from 'vue-router'
import http from '@/http'
import API from '@/http/api'

interface PropsData {
  type: string // 1 确认订单 2 订单列表
  token: string
  data: { [key: string]: any } // type 1: data: {outTradeNo: ''}  type 2: data: {id: ''}
}

export default defineComponent({
  name: 'Preview',
  setup() {
    const route = useRoute()
    const type = route.query.type as string
    const token = route.query.token as string
    const data = route.query.data as string
    let url = API.pollingOrderDetail
    const paramsData: PropsData = { type, token, data: {} }
    if (type === '1') {
      paramsData.data = { outTradeNo: data }
    } else {
      paramsData.data = { id: data }
      url = API.checkPollingOrder
    }

    const detail = ref<Record<string, any>>({})

    async function request() {
      http.defaults.baseURL = process.env.VUE_APP_WECHAT_API
      http.defaults.headers.common['token'] = paramsData.token
      const res: any = await http({
        url,
        method: 'post',
        data: paramsData.data
      })
      detail.value = res.result
      detail.value.upperPrice = numToUpper(detail.value.price * 0.01)
      detail.value.orders = buildOrdersTextList(detail.value.orders || [], res.result.typeExpense)
    }

    function getCanvasBlob(canvas: HTMLCanvasElement) {
      return new Promise<Blob | null>(resolve => {
        canvas.toBlob(blob => {
          resolve(blob)
        })
      })
    }

    async function screenshotAndUpload(el: HTMLElement) {
      // scale 默认是 window.devicePixelRatio 的值，在retina屏上为2或者在低分频率屏幕下为0.5，这边要保证截图宽度的一致性（375）, 设置固定像素比 scale: 1
      const canvas = await html2canvas(el, { width: 375, useCORS: true, scale: 1 })
      const canvasBlob = await getCanvasBlob(canvas)
      if (canvasBlob) {
        return canvasBlob
      }
    }
    const saveImage = async () => {
      const el = document.getElementById('canvas-ares') as HTMLElement
      //   await nextTick()
      //   const blob = await screenshotAndUpload(el)
      //   if (blob) {
      //     console.log(blob)
      //     // const file = new File([blob], 'screenshot.png')
      //     wx.miniProgram.postMessage({ data: blob }) // 注册postMessage方法，参数是base64格式
      //     wx.miniProgram.navigateBack() // 小程序返回上级，通过销毁页面，触发postMessage
      //   }
      const canvas = await html2canvas(el, { useCORS: true, scale: 1 })
      const base64 = canvas.toDataURL('image/png')
      const image = document.getElementById('test-img') as HTMLImageElement
      image.src = base64
      wx.miniProgram.postMessage({ data: base64 })
      setTimeout(() => {
        wx.miniProgram.redirectTo({
          url: '/pages/previewImage/index'
        })
      }, 500)
      // 注册postMessage方法，参数是base64格式
      //   wx.miniProgram.navigateBack() // 小程序返回上级，通过销毁页面，触发postMessage
    }

    function buildOrdersTextList(list: Record<string, any>[], typeExpense: number) {
      const _list = list.map(item => {
        const name = typeExpense === 2 ? item.companyName : item.dockingFactoryName
        if (item.deviceId && name) {
          return `${item.carNumber}(${item.deviceId}-${name})`
        } else if (item.deviceId && !name) {
          return `${item.carNumber}(${item.deviceId})`
        } else if (!item.deviceId && name) {
          return `${item.carNumber}(${name})`
        } else {
          return item.carNumber
        }
      })
      return _list
    }

    function numToUpper(money: any) {
      //汉字的数字
      let cnNums: any = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
      //基本单位
      let cnIntRadice: any = ['', '拾', '佰', '仟']
      //对应整数部分扩展单位
      let cnIntUnits: any = ['', '万', '亿', '兆']
      //对应小数部分单位
      let cnDecUnits: any = ['角', '分', '毫', '厘']
      //整数金额时后面跟的字符
      let cnInteger = '整'
      //整型完以后的单位
      let cnIntLast = '元'
      //最大处理的数字
      let maxNum = 999999999999999.9999
      //金额整数部分
      let integerNum
      //金额小数部分
      let decimalNum
      //输出的中文金额字符串
      let chineseStr = ''
      //分离金额后用的数组，预定义
      let parts
      if (money == '') {
        return ''
      }
      money = parseFloat(money)
      if (money >= maxNum) {
        //超出最大处理数字
        return ''
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger
        return chineseStr
      }
      //转换为字符串
      money = money.toString()
      if (money.indexOf('.') == -1) {
        integerNum = money
        decimalNum = ''
      } else {
        parts = money.split('.')
        integerNum = parts[0]
        decimalNum = parts[1].substr(0, 4)
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0
        let IntLen = integerNum.length
        for (let i = 0; i < IntLen; i++) {
          let n = integerNum.substr(i, 1)
          let p = IntLen - i - 1
          let q = p / 4
          let m = p % 4
          if (n == '0') {
            zeroCount++
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0]
            }
            //归零
            zeroCount = 0
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q]
          }
        }
        chineseStr += cnIntLast
      }
      //小数部分
      if (decimalNum != '') {
        let decLen = decimalNum.length
        for (let i = 0; i < decLen; i++) {
          let n = decimalNum.substr(i, 1)
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i]
          }
        }
      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger
      } else if (decimalNum == '') {
        chineseStr += cnInteger
      }
      return chineseStr
    }

    onMounted(() => {
      // saveImage()
      request()
    })
    return {
      detail,
      saveImage
    }
  }
})
